// App.js - WEB
import React, { Component } from 'react';
import { View } from 'react-native';
import firebase from 'firebase';
import { connect } from 'react-firebase';
import { BuilderProvider } from '@builder/component-library';

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import StripePayments from "../../blocks/stripepayments/src/StripePayments";
import Linkingandworkingofpages from "../../blocks/linkingandworkingofpages/src/Linkingandworkingofpages";
import Savepreviewpublishthewebsite6 from "../../blocks/savepreviewpublishthewebsite6/src/Savepreviewpublishthewebsite6";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import Staticpagescustomisation4 from "../../blocks/staticpagescustomisation4/src/Staticpagescustomisation4";
import Customisebuildercompletepage5 from "../../blocks/customisebuildercompletepage5/src/Customisebuildercompletepage5";
import FeatureSettings from "../../blocks/featuresettings/src/FeatureSettings";
import ApiIntegration from "../../blocks/apiintegration/src/ApiIntegration";



const routeMap = {
StripePayments:{
 component:StripePayments,
path:"/StripePayments"},
Linkingandworkingofpages:{
 component:Linkingandworkingofpages,
path:"/Linkingandworkingofpages"},
Savepreviewpublishthewebsite6:{
 component:Savepreviewpublishthewebsite6,
path:"/Savepreviewpublishthewebsite6"},
Catalogue:{
 component:Catalogue,
path:"/Catalogue"},
Staticpagescustomisation4:{
 component:Staticpagescustomisation4,
path:"/Staticpagescustomisation4"},
Customisebuildercompletepage5:{
 component:Customisebuildercompletepage5,
path:"/Customisebuildercompletepage5"},
FeatureSettings:{
 component:FeatureSettings,
path:"/FeatureSettings"},
ApiIntegration:{
 component:ApiIntegration,
path:"/ApiIntegration"},

  Home: {
    component: HomeScreen,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  }

};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {
   
  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');
    
    return (
      <BuilderProvider>
        <View style={{ height: '100vh', width: '100vw' }}>
          <TopNav />
          {WebRoutesGenerator({ routeMap })}
          <ModalContainer />
        </View>
      </BuilderProvider>
    );
  }
}

export default App;